<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'form' })"
                    >Définition des formulaires</vk-breadcrumb-item
                >
                <vk-breadcrumb-item :disabled="true">Formulaire</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="m-3">
            <FormFieldAccordionVue class="mb-3" :formDefinitionId="$route.params.formId"></FormFieldAccordionVue>
        </div>
    </div>
</template>
<script>
import FormFieldAccordionVue from '../components/forms/FormFieldAccordion.vue'
export default {
    components: {
        FormFieldAccordionVue,
    },
}
</script>
