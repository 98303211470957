<template>
    <div>
        <div class="text-center pb-4">{{ FormDefinitionById.wording }}</div>
        <simple-accordion :always-open="true">
            <template #accordionTitle>
                <span class="me-3">Champs du formulaire</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table :selected-rows="[]" :columns="columns" :rows="$store.state.adminForm.formFields">
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                            </div>
                            <div v-else-if="props.column.field === 'visible'" class="uk-text-center">
                                {{ props.row.visible ? 'OUI' : 'NON' }}
                            </div>
                            <div v-else-if="props.column.field === 'editable'" class="uk-text-center">
                                {{ props.row.editable ? 'OUI' : 'NON' }}
                            </div>
                            <div v-else-if="props.column.field === 'required'" class="uk-text-center">
                                {{ props.row.required ? 'OUI' : 'NON' }}
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFormField" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleModal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="field_definition">Définition des champs</label>
                        <select id="field_definition" class="uk-select" v-model="fieldDefinition">
                            <option :value="undefined" v-if="!fieldDefinitions[0]">Pas de list</option>
                            <option v-for="(it, idx) in fieldDefinitions" :key="idx" :value="it">
                                {{ it.g2_code }}
                            </option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="visible" class="c-mr">Visible</label>
                        <input id="visible" class="uk-checkbox" type="checkbox" v-model="form.visible" />
                    </div>
                    <div class="uk-margin">
                        <label for="editable" class="c-mr">Editable</label>
                        <input id="editable" class="uk-checkbox" type="checkbox" v-model="form.editable" />
                    </div>
                    <div class="uk-margin">
                        <label for="required" class="c-mr">Requis</label>
                        <input id="required" class="uk-checkbox" type="checkbox" v-model="form.required" />
                    </div>
                    <div class="uk-margin">
                        <label for="form_field_order" class="c-mr">Ordre</label>
                        <input
                            id="form_field_order"
                            class="uk-number"
                            type="number"
                            min="1"
                            v-model="form.form_field_order"
                        />
                        <div v-if="formFieldOrderIsAlreadyUsed">
                            <span style="color: red">Ordre déjà utilisé (pensez à le modifier)</span>
                        </div>
                        <div v-if="!formFieldOrderIntegrityIsAlright">
                            <span style="color: red">L'ordre doit être un nombre positif</span>
                        </div>
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary" :disabled="!formFieldOrderIntegrityIsAlright">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody>
                <p class="text-danger">Voulez-vous supprimer ce champ dans le formulaire ?</p>
            </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: ['formDefinitionId'],
    data() {
        return {
            loading: false,
            titleModal: '',
            FormDefinitionById: '',
            form: {
                visible: true,
                editable: true,
                required: true,
                form_field_order: 1,
            },
            columns: [
                // {
                //   label: 'Définition de formulaire',
                //   field: 'form_definition.wording',
                // },
                {
                    label: 'Définition de champ',
                    field: 'field_definition.g2_code',
                },
                {
                    label: 'Visible',
                    field: 'visible',
                },
                {
                    label: 'Editable',
                    field: 'editable',
                },
                {
                    label: 'Requis',
                    field: 'required',
                },
                {
                    label: 'Ordre',
                    field: 'form_field_order',
                    sortFn: (x, y) => (x < y ? -1 : x > y ? 1 : 0),
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            fieldDefinition: undefined,
            formDefinition: undefined,
            selected: [],
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                visible: true,
                editable: true,
                required: true,
                form_field_order: 1,
            }
        },
        onPLusClicked() {
            this.titleModal = 'Nouveau champs du formulaire'
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormField.$el).show()
        },
        onSave() {
            if (this.form.form_field_order === '') {
                this.$store.dispatch('notifications/showWarning', 'Veuillez entrer l`ordre de ce formulaire')
                return
            }
            if (this.form.form_field_order < 1) {
                this.$store.dispatch('notifications/showWarning', "L'ordre doit être supérieur ou égal à 1.")
                return
            }
            if (!this.fieldDefinition) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez créer d`abord une définition de champ')
                return
            }
            const data = {
                visible: this.form.visible,
                editable: this.form.editable,
                required: this.form.required,
                form_field_order: this.form.form_field_order,
                field_definition: this.fieldDefinition.id,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateFormField(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormField.$el).hide()
                    this.$store.dispatch('adminForm/setFormFields', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addFormField(this.formDefinitionId, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormField.$el).hide()
                    this.$store.dispatch('adminForm/setFormFields', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.titleModal = 'Modification champs du formulaire'
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormField.$el).show()
        },
        async getAllformField() {
            this.loading = true
            this.$http
                .getAllFormFieldByFormDefinition(this.formDefinitionId)
                .then((res) => {
                    this.$store.dispatch('adminForm/setFormFields', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async getAllFieldDefinition() {
            const rows = await this.$http.getAllFieldDefinition()
            await this.$store.dispatch('adminForm/setFieldDefinitions', rows)
        },
        updateAll() {
            this.getAllformField()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteFormField(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                visible: data.visible,
                editable: data.editable,
                required: data.required,
                form_field_order: data.form_field_order,
            }
            this.fieldDefinition = data.field_definition
        },
        getFormDefinitionById() {
            this.$http.getFormDefinitionById(this.formDefinitionId).then((res) => {
                this.FormDefinitionById = res
            })
        },
    },
    computed: {
        ...mapState('adminForm', { formFields: 'formFields' }),
        ...mapState('adminForm', { fieldDefinitions: 'fieldDefinitions' }),
        ...mapState('adminForm', { formDefinitions: 'formDefinitions' }),
        formFieldOrderIntegrityIsAlright() {
            const number = Number(this.form.form_field_order.toString())
            return number > 0
        },
        formFieldOrderIsAlreadyUsed() {
            if (!this.fieldDefinition) return false
            return (
                this.formFields.filter((item) => {
                    return (
                        item.field_definition.id !== this.fieldDefinition.id &&
                        item.form_field_order.toString() === this.form.form_field_order.toString()
                    )
                }).length > 0
            )
        },
    },
    watch: {
        'form.visible'(val) {
            // eslint-disable-next-line no-empty
            if (val) {
            } else {
                this.form.required = false
                this.form.editable = false
            }
        },
        'form.required'(val) {
            if (val) {
                this.form.visible = true
                this.form.editable = true
            }
        },
        'form.editable'(val) {
            if (val) {
                if (!this.form.visible) this.form.visible = true
            } else {
                if (this.form.required) this.form.required = false
            }
        },
    },
    mounted() {
        this.getAllFieldDefinition()
        this.getAllformField()
        this.getFormDefinitionById()
    },
}
</script>

<style scoped>
.text-danger {
    font-weight: bold;
}

label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
